import * as PropTypes from "prop-types"
import styled from "styled-components"
import Icon from "../../icons/icon"
import Arrow from "../../icons/arrow"

const Wrapper = styled.div`
  position: absolute;
  ${({ $side }) => `${$side}: -25px;`}
  top: 50%;
  margin-top: -20px;
  z-index: 2;
  cursor: pointer;
`

const StyledIcon = styled(Icon)`
  transform: rotate(${({ $side }) => ($side === "left" ? "180deg" : "0")});
`

const SliderArrow = props => {
  const { side, onClick = () => {} } = props

  return (
    <Wrapper $side={side} onClick={onClick} className="slider-arrow">
      <StyledIcon Icon={Arrow} $side={side} color="deepPink" />
    </Wrapper>
  )
}

SliderArrow.propTypes = {
  side: PropTypes.string.isRequired,
  onClick: PropTypes.func
}

export default SliderArrow
