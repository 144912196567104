import styled from "styled-components"
import * as PropTypes from "prop-types"
import orderBy from "lodash/orderBy"
import { sendEvent } from "@/scripts/GoogleAnalytics"
import Typography from "@/styled/Typography"
import { Container } from "@/styled/Grid"
import Slider from "../Slider"
import Article from "./Article"

const Wrapper = styled.div`
  margin: 25px -25px 0;
  svg {
    color: ${({ theme }) => theme.colors.skyBlue};
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    height: inherit;
    > div {
      height: 100%;
    }
  }
`

const Link = styled.a`
  color: ${({ theme }) => theme.colors.skyBlue};
  text-decoration: underline;
`

const BlogLinks = styled(Typography)`
  margin-top: 25px;
  @media ${({ theme }) => theme.breakpoints.lg} {
    text-align: center;
  }
  a:first-child {
    margin-right: 5px;
  }
  a:last-child {
    margin-left: 5px;
  }
`

export default function Articles(props) {
  const { posts = [], page, title = "Novedades del Blog" } = props

  if (posts.length < 4) return null
  const orderedPosts = orderBy(posts, p => +p[`priority_${page}`]).slice(0, 9)

  return (
    <Container $top={25} $bottom={50}>
      <Typography variant="title2" $weight="medium" center>
        {title}
      </Typography>
      <Wrapper>
        <Slider
          sliderSettings={{
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: false,
            dots: true,
            responsive: [
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ],
            afterChange: i => sendEvent("Click Blog", { event_category: page, event_label: `slide to ${i}` })
          }}
        >
          {orderedPosts.map(post => (
            <Article key={post.sys.id} post={post.fields} page={page} />
          ))}
        </Slider>
      </Wrapper>
      <BlogLinks variant="small" $weight="light" $txtColor="skyBlue">
        <Link href="/blog">Ver todos los artículos</Link>
      </BlogLinks>
    </Container>
  )
}

Articles.propTypes = {
  posts: PropTypes.array.isRequired,
  page: PropTypes.string.isRequired,
  title: PropTypes.string
}
