import Slick from "react-slick"
import * as PropTypes from "prop-types"
import styled from "styled-components"
import SliderArrow from "./SliderArrow"

const Dot = styled.div`
  cursor: auto;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: ${({ theme }) => theme.colors.grayBlue};
  opacity: 0.2;
  cursor: pointer;
  .slick-active & {
    margin-top: -2px;
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.colors.primary};
    opacity: 1;
  }
`

const Wrapper = styled.div`
  .slick-slider {
    display: block;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -khtml-user-select: none;
  }
  .slick-list {
    display: block;
    position: relative;
    margin: 0;
    overflow: hidden;
    padding: 0 0 25px 0;
  }
  .slick-track {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;
  }
  .slick-track:after,
  .slick-track:before {
    content: "";
    display: table;
  }
  .slick-track:after {
    clear: both;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    img {
      display: block;
    }
  }
  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-dots {
    padding: 0;
    width: auto;
    -webkit-box-pack: center;
    justify-content: center;
    display: flex !important;
    margin: 0px auto;
    li {
      display: inline-block;
      margin: 0px 5px;
    }
  }
`

export default function Slider(props) {
  const { children, sliderSettings = {}, sliderRef = null } = props

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderArrow side="right" />,
    prevArrow: <SliderArrow side="left" />,
    customPaging: () => <Dot />,
    ...sliderSettings
  }

  return (
    <Wrapper>
      <Slick ref={sliderRef} {...settings}>
        {children}
      </Slick>
    </Wrapper>
  )
}

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  sliderSettings: PropTypes.object,
  sliderRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
}
